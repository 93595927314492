<template>
  <div>
    <b-row>
      <b-col class="d-flex justify-content-end">
        <b-button
          v-show="isModuleActualEqualTwo"
          variant="success"
          class="mr-2 mb-1"
          @click="openModalSearch()"
        >
          <feather-icon
            icon="SearchIcon"
            size="15"
            class="mr-50 text-white"
          />Payments Search
        </b-button>
      </b-col>
    </b-row>
    <header-slot :clients-search="true">
      <template v-if="changeNavAction == true" #actions>
        <b-row class="w-100">
          <!-- Title Search Global -->
          <!-- Input Search Global -->
          <b-col cols="12" class="pl-0">
            <b-input-group class="mr-1">
              <b-input-group-prepend>
                <b-button variant="outline-info"> GLOBAL SEARCH</b-button>
              </b-input-group-prepend>
              <b-form-input
                v-model="searchText"
                placeholder="Search by Name, Account or the last 4 digits of Phone number"
                @keyup.enter="search"
              />
              <b-input-group-append>
                <b-button variant="primary" @click="search">
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </template>
    </header-slot>
    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view key="quality-clients-tab" />
    </b-card>
    <!-- Modal Search Global -->
    <modal-global-search
      v-if="modalGlobalSearch"
      :search-text="searchText"
      :program-id="programId"
      :role-id="currentUser.role_id"
      @closeModal="closeGlobalSearch"
    />
    <modal-search-payment
      v-if="openModalSearchPayment"
      @close="closeModalSearch"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// Import Service
import chargeBackService from "@/views/commons/components/charge-back_v2/service/charge.back.service";
// Import Components
import ModalSearchPayment from "@/views/commons/components/charge-back_v2/view/modal/ModalSearchPayment.vue";
import ModalGlobalSearch from "@/views/commons/components/clients/modals/ModalGlobalSearch.vue";

export default {
  components: {
    ModalGlobalSearch,
    ModalSearchPayment,
  },
  data() {
    return {
      openModalSearchPayment: false,
      moduleActual: null,
      permitStatus: null,
      searchText: "",
      modalGlobalSearch: false,
      changeNavAction: true,
      tabs: [
        {
          route: "tax-research-clients-tab",
          name: "Clients",
        },
        {
          route: "tax-research-shared-tab",
          name: "Shared",
        },
      ],
      programId: 3,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    isModuleActualEqualTwo() {
      return this.permitStatus === 1;
    },
  },
  mounted() {
    this.getAccessModules();
  },
  created() {
    this.moduleId();
  },
  methods: {
    async getAccessModules() {
      try {
        const params = {
          module_actual: this.moduleActual,
        };
        const result = await chargeBackService.getAccessModule(params);
        this.permitStatus = result[0]?.permit_status;
      } catch (error) {
        console.error(error);
      }
    },
    moduleId() {
      this.moduleActual = this.$route.matched[0].meta.module;
    },
    openModalSearch() {
      this.openModalSearchPayment = true;
    },
    closeModalSearch() {
      this.openModalSearchPayment = false;
    },
    changeNav(value) {
      this.changeNavAction = value;
    },
    async search() {
      this.modalGlobalSearch = true;
    },
    closeGlobalSearch() {
      this.modalGlobalSearch = false;
    },
  },
};
</script>

<style>
.bg_primary a {
  background: #0090e7;
  color: #fff !important;
}
</style>
